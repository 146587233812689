<template>
  <div class="sub-header--container">
    <slot />
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss">
.sub-header--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
