<template>
  <div class="properties-container">
    <h5>{{ $t('Views.Resource.Information.properties') }}</h5>
    <div v-if="Object.keys(zone?.Properties).length">
      <b-table
        id="properties-table"
        hover
        :items="zoneProperties"
        :fields="fields"
        :per-page="propertiesPerPage"
        :current-page="propertiesCurrentPage"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
        class="properties-table"
      >
        <template #cell(value)="data">
          <a
            v-if="REGEX.URL.test(data.value)"
            :href="data.value"
          >
            {{ data.value }}
          </a>
          <p v-else>
            {{ data.value }}
          </p>
        </template>
      </b-table>
      <div class="pagination_container">
        <PaginationController
          v-if="zoneProperties.length > propertiesPerPage"
          v-model="propertiesCurrentPage"
          :max-pages="maxPropertiesPages"
          :allow-show-all="false"
          class="pagination"
        />
      </div>
      <b-pagination
        v-model="propertiesCurrentPage"
        :total-rows="zoneProperties.length"
        :per-page="propertiesPerPage"
        aria-controls="properties-table"
      />
    </div>
    <div v-else>
      <h6>{{ $t('Views.Resource.Information.no_properties_available') }}</h6>
    </div>
  </div>
</template>

<script>
import PaginationController from '@/components/pagination/pagination.controller.vue';
import { BTable } from 'bootstrap-vue';
import { REGEX } from '@/functions/regex.helper';

export default {
  components: {
    PaginationController,
    BTable,
  },
  props: {
    zone: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      REGEX,
      orderBy: 'name',
      orderDesc: true,
      propertiesCurrentPage: 1,
      propertiesPerPage: 6,
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thClass: 'properties-table-header',
        },
        {
          key: 'value',
          label: 'Value',
          sortable: true,
          thClass: 'properties-table-header',
        },
      ],
    };
  },
  computed: {
    zoneProperties() {
      return Object.entries(this.zone?.Properties).map(([k, v]) => ({ name: k, value: v }));
    },
    maxPropertiesPages() { return Math.ceil(this.zoneProperties.length / this.propertiesPerPage) || 1; },
  },

};
</script>

<style lang="scss">
.properties-table {
  .properties-table-header {
    width: 50%;
    border-top: unset;
  }

  p {
    margin: unset;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td, a {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 0;
    white-space: nowrap;
  }
}
</style>
