<template>
  <div v-if="initialized">
    <Header
      class="flex"
      :back="true"
      :text="$t('Components.Generic.back')"
      :title="resourceName + ' - ' + $t('Views.Resource.Information.header')"
    >
      <h5 class="header-title">
        {{ $t('Views.Resource.Information.header') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <!-- SubHeader -->
        <SubHeader>
          <h5>{{ $t('Views.Resource.Information.equipment') }}</h5>
          <Button
            v-if="isIssueReportingActive && !UserState.user?.AbstractUser"
            size="tiny"
            variant="pastel-primary"
            @click="navigateToIssueReporting"
          >
            {{ $t('Views.Resource.Information.report_issue') }}
          </Button>
        </SubHeader>
        <div class="resource-information__equipment_list">
          <div
            v-for="(eq, index) in equipment"
            :key="index"
            class="equipment_list--item"
          >
            <svg-icon
              :src="`/icons/eq-icons/${eq.Icon}.svg`"
              class="equipment__icon"
            />
            <p class="eq-title small-semi secondary unselectable">
              {{ eqTitle(eq) }}
            </p>
          </div>
          <div
            v-if="!equipment.length"
            class="no__equipment"
          >
            <p class="small-semi secondary unselectable">
              {{ $t('Views.Resource.Issue.no_equipment') }}
            </p>
          </div>
        </div>
      </Card>

      <Card position="bottom">
        <ResourceProperties
          v-if="UserService.hasAllRoles(['role_user_roles']) && isDevelop"
          :zone="zone"
        />
      </Card>
    </div>
  </div>
</template>

<script>
/** Components */
import UserState from '@/singletons/user.state.singleton';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import Button from '@/components/button/button.vue';
import SubHeader from '@/components/headers/sub.header.vue';

/** Services */
import CompanyService from '@/Services/Company/company.service';
import UserService from '@/Services/User/user.service';
import ResourceProperties from '@/views/book/components/resource.properties.vue';

export default {
  components: {
    Header,
    Card,
    Button,
    SubHeader,
    ResourceProperties,
  },
  data() {
    return {
      UserState,
      UserService,
      initialized: false,
      isIssueReportingActive: false,
      equipment: [],
      isDevelop: ['development', 'development.local'].includes(process.env.VUE_APP_MODE),
    };
  },

  computed: {
    resourceName() { return this.zone?.Name; },
  },

  beforeCreate() {
    const { zid } = this.$route.params;
    this.zid = Number(zid) || 0;
  },

  async created() {
    this.zone = await CompanyService.getCachedZone(this.zid);
    this.floor = await CompanyService.getCachedZone(this.zone.ParentZone);
    this.checkIssueReportProperties();
    this.equipment = this.zone.Equipment;
    this.initialized = true;
  },

  methods: {
    checkIssueReportProperties() {
      const { Properties } = this.floor;
      const facilityManager = !!Properties.facility_manager;
      const techSupport = !!Properties.tech_support;

      this.isIssueReportingActive = (facilityManager || techSupport);
    },
    navigateToIssueReporting() {
      this.$router.push({ path: `${this.$route.path}/issue`, query: this.$route.query });
    },
    eqTitle(eq) {
      if (!eq.Title) return '...';
      if (typeof eq.Value === 'string') {
        return `${eq.Value} ${eq.Title}`;
      }
      return eq.Title;
    },
  },
};
</script>
<style lang="scss">
  .resource-information__equipment_list {
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
  }

  .equipment_list--item {
    min-height: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 25%;
    color: var(--secondary-font-color);
    svg{
      fill: var(--secondary-font-color);
    }
    .eq-title{
      text-align: center;
    }
  }
</style>
